
import { loadImage } from "../helpers/dom"

//Player Ball Icons
import Baseball_Icon from "../Sprites/PlayerIcons/Baseball_icon.png";
import Basketball_Icon from "../Sprites/PlayerIcons/BasketBall_icon.png";
import Bowlingball_Icon from "../Sprites/PlayerIcons/BowlingBall_icon.png";
import Football_Icon from "../Sprites/PlayerIcons/Football_icon.png";
import Golfball_Icon from "../Sprites/PlayerIcons/GolfBall_icon.png";
import Racketball_Icon from "../Sprites/PlayerIcons/RacketBall_icon.png";
import Soccerball_Icon from "../Sprites/PlayerIcons/SoccerBall_icon.png";
import Tennisball_Icon from "../Sprites/PlayerIcons/TennisBall_icon.png";
import Volleyball_Icon from "../Sprites/PlayerIcons/VolleyBall_icon.png";
import Hockeypuck_Icon from "../Sprites/PlayerIcons/HockeyPuck_icon.png";

//Player BackgroundColors
import PI_BKG_red from "../Sprites/PlayerIcons/PIBKG_red.png";
import PI_BKG_orange from "../Sprites/PlayerIcons/PIBKG_orange.png";
import PI_BKG_yellow from "../Sprites/PlayerIcons/PIBKG_yellow.png";
import PI_BKG_green from "../Sprites/PlayerIcons/PIBKG_green.png";
import PI_BKG_blue from "../Sprites/PlayerIcons/PIBKG_blue.png";
import PI_BKG_indigo from "../Sprites/PlayerIcons/PIBKG_indigo.png";
import PI_BKG_violet from "../Sprites/PlayerIcons/PIBKG_violet.png";
import PI_BKG_purple from "../Sprites/PlayerIcons/PIBKG_purple.png";
import PI_BKG_magenta from "../Sprites/PlayerIcons/PIBKG_magenta.png";
import PI_BKG_grey from "../Sprites/PlayerIcons/PIBKG_grey.png";
import PI_BKG_charcoal from "../Sprites/PlayerIcons/PIBKG_charcoal.png";

//Team Logos
import TeamRed from "../Sprites/TeamIcons/TeamRed.png";
import TeamOrange from "../Sprites/TeamIcons/TeamOrange.png";
import TeamYellow from "../Sprites/TeamIcons/TeamYellow.png";
import TeamGreen from "../Sprites/TeamIcons/TeamGreen.png";
import TeamBlue from "../Sprites/TeamIcons/TeamBlue.png";
import TeamPurple from "../Sprites/TeamIcons/TeamPurple.png";
import TeamPink from "../Sprites/TeamIcons/TeamPink.png"
import { CarcadeLogo, golfLogo, shuffleboardLogo, soccerLogo } from "./ImagesLoader";


export const preloadImages = async () => {
    await Promise.all(
      [
        Baseball_Icon,
        Basketball_Icon,
        Bowlingball_Icon,
        Football_Icon,
        Golfball_Icon,
        Racketball_Icon,
        Soccerball_Icon,
        Tennisball_Icon,
        Volleyball_Icon,
        Hockeypuck_Icon,
        PI_BKG_red,
        PI_BKG_orange,
        PI_BKG_yellow,
        PI_BKG_green,
        PI_BKG_blue,
        PI_BKG_indigo,
        PI_BKG_violet,
        PI_BKG_purple,
        PI_BKG_magenta,
        PI_BKG_grey,
        PI_BKG_charcoal,
        TeamRed,
        TeamOrange,
        TeamYellow,
        TeamGreen,
        TeamBlue,
        TeamPurple,
        TeamPink
        
      ].map(loadImage),
    )
  
    void Promise.all(
      [
        Baseball_Icon,
        Basketball_Icon,
        Bowlingball_Icon,
        Football_Icon,
        Golfball_Icon,
        Racketball_Icon,
        Soccerball_Icon,
        Tennisball_Icon,
        Volleyball_Icon,
        Hockeypuck_Icon,
        PI_BKG_orange,
        PI_BKG_yellow,
        PI_BKG_green,
        PI_BKG_blue,
        PI_BKG_indigo,
        PI_BKG_violet,
        PI_BKG_purple,
        PI_BKG_magenta,
        PI_BKG_grey,
        PI_BKG_charcoal,
        TeamRed,
        TeamOrange,
        TeamYellow,
        TeamGreen,
        TeamBlue,
        TeamPurple,
        TeamPink
        
      ].map(loadImage),


    )
  }
  
  export const GetPlayerIcon = (name) =>{
    let ball = Baseball_Icon;
    //console.log("NAME :::: ", name);
    
    if(name != null){
      if(name.toLowerCase().includes('baseball')) ball = Baseball_Icon;
      else if(name.toLowerCase().includes('basketball')) ball = Basketball_Icon;
      else if(name.toLowerCase().includes('bowlingball')) ball = Bowlingball_Icon;
      else if(name.toLowerCase().includes('football')) ball = Football_Icon;
      else if(name.toLowerCase().includes('golfball')) ball = Golfball_Icon;
      else if(name.toLowerCase().includes('hockeypuck')) ball = Hockeypuck_Icon;
      else if(name.toLowerCase().includes('racketball')) ball = Racketball_Icon;
      else if(name.toLowerCase().includes('soccerball')) ball = Soccerball_Icon;
      else if(name.toLowerCase().includes('tennisball')) ball = Tennisball_Icon;
      else if(name.toLowerCase().includes('volleyball')) ball = Volleyball_Icon;
    } 
   
  
  
    return ball;
  }

export const GetPlayerBackground = (name) =>{
  let bkg = PI_BKG_charcoal;

  if(name != null){
    if(name.toLowerCase().includes('red'))           bkg = PI_BKG_red;
    else if(name.toLowerCase().includes('orange'))   bkg = PI_BKG_orange;
    else if(name.toLowerCase().includes('yellow'))   bkg = PI_BKG_yellow;
    else if(name.toLowerCase().includes('green'))    bkg = PI_BKG_green;
    else if(name.toLowerCase().includes('blue'))     bkg = PI_BKG_blue;
    else if(name.toLowerCase().includes('indigo'))   bkg = PI_BKG_indigo;
    else if(name.toLowerCase().includes('violet'))   bkg = PI_BKG_violet;
    else if(name.toLowerCase().includes('purple'))   bkg = PI_BKG_purple;
    else if(name.toLowerCase().includes('magenta'))  bkg = PI_BKG_magenta;
    else if(name.toLowerCase().includes('grey'))     bkg = PI_BKG_grey;
    else if(name.toLowerCase().includes('charcoal')) bkg = PI_BKG_charcoal;
  }
  

  return bkg;
}

export const GetPlayerBackgroundAsColor = (name) =>{
  let c = 'black';

  if(name != null){
    if(name.toLowerCase().includes('red'))           c = '#da3439';
    else if(name.toLowerCase().includes('orange'))   c = '#db8537';
    else if(name.toLowerCase().includes('yellow'))   c = '#dbc235';
    else if(name.toLowerCase().includes('green'))    c = '#47db34';
    else if(name.toLowerCase().includes('blue'))     c = '#35addb';
    else if(name.toLowerCase().includes('indigo'))   c = '#3778db';
    else if(name.toLowerCase().includes('violet'))   c = '#ce84f7';
    else if(name.toLowerCase().includes('purple'))   c = '#9948d0';
    else if(name.toLowerCase().includes('magenta'))  c = '#db348b';
    else if(name.toLowerCase().includes('grey'))     c = '#d4d4d4';
    else if(name.toLowerCase().includes('charcoal')) c = '#2d2d2d';
  }
  

  return c;
}

export const GetTeamlessGameNameAsColor = (name) =>{
  let c = 'black';

  if(name != null){
    if(name.toLowerCase().includes('golf'))          c = '#47db34';
  }
  

  return c;
}

export const GetTeamIcon = (teamName) =>{
  
  let name = teamName.replace('/', '');

  switch(name.toLowerCase()){
      case 'blaze': return TeamRed;
      case 'manes': return TeamOrange;
      case 'kamori': return TeamYellow;
      case 'aurochs': return TeamGreen;
      case 'stampede': return TeamGreen;
      case 'rhinos': return TeamBlue;
      case 'wardens': return TeamPurple;
      case 'unicorns': return TeamPink;
  }

  return null;
}


  
