import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { soccerTouchArea, GenericReticule, soccerBkgSplash, soccerLogo } from "../Sprites/ImagesLoader";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PageLanding, PlayerBanner } from "../Components/PagePrefabs";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameOverResultsPage, GameplayArea } from "../Components/GameplayPrefabs";
import { TextBox } from "../Components/PagePrefabs.js";


export default function Soccer(props){
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);

    const[TrackingTouch, setTrackingTouch ]= useState(false);

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect, RequestGameInformationData} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages, NavigateToPlayPage, Latency} = CoreGameConnectionsHooks(context, gameStateContext, teamsContext);

    useEffect(() => {
        //OnMount Things happen here
        document.title = "Soccer Controller";
        RequestGameInformationData();

        websocket.AddListener(onPayload);
        websocket.AddDisconnectListener(ShowReconnect);
        window.addEventListener('beforeunload', handleTabClosing);
        //console.log(context.user);
        if(websocket.sessionId === '') Reconnect();
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        //console.log(context.user.playerID);
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            websocket.RemoveDisconnectListener(ShowReconnect);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    const ShowReconnect = () => {
        gameStateContext.DisconnectedFromServer();
    }

    function onPayload(id, payload, senderContext){
        CheckCoreGameConnectionMessages(id, payload, senderContext);
        CheckReconnectMessages(id,payload, senderContext, NavigateToPlayPage);

        //Game specific calls
        switch(id){
           
            default:
                break;
        }
    }

  

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("Soccer_Tracking", payload);

        setTrackingTouch(true);
    }

    function TrackingEnded(a, cancel){
        if(cancel) SendSpecificMessageToServer("Soccer_CancelTracking");
        else SendSpecificMessageToServer("Soccer_Fire");

        setTrackingTouch(false);
    }

    const TouchInfo = {
        bkg: soccerTouchArea,
        reticule: GenericReticule,
        sendArray: false,
        rate: 15,
        tracking: TrackingTouch,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded
    }

    const ShouldBlurBackground = useMemo(() => { return gameStateContext.GameState.isPaused === 'true' ? true : !gameStateContext.GameState.myTurn }, [gameStateContext.GameState.myTurn, gameStateContext.GameState.isPaused])


    return (

    <>
        {gameStateContext.GameState.isOnGameLandingPage ? 
        <PageLanding bkgImage={soccerBkgSplash} useLogo Logo={soccerLogo} Latency={Latency} Disconnected={gameStateContext.GameState.disconnected} />
        :
        <>
        <PageBackground bkgImage={soccerBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!gameStateContext.GameState.joinedGame} Logo={soccerLogo}/>            
        
            <PlayerBanner myColor={teamsContext.Teams.myteam.color} Latency={Latency}/>
            {gameStateContext.GameState.gameOver  ? <GameOverResultsPage Game={'soccer'} GameOverData={gameStateContext.GameOverData} Teams={teamsContext.Teams} isCoreMode={gameStateContext.GameState.isCoreMode}  /> : 
                
                (!gameStateContext.GameState.teamsSelected ? <TeamSelection TeamContext={teamsContext} GameState={gameStateContext.GameState} smts={(msg) => SendSpecificMessageToServer(msg)} /> : 
                    <GameplayArea 
                    game="Soccer" 
                    TouchBoxInfo={TouchInfo} 
                    useBlur={ShouldBlurBackground} 
                    GameContext={gameStateContext}
                    TeamsContext={teamsContext}
                    Logo={soccerLogo} />    
                )      
            }
        </>
    }

    </>
       
    );
}

/*
        <PageBackground bkgImage={soccerBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!GameStateRef.current.joinedGame} Logo={soccerLogo}/>

        <div style={{position: "relative", width: "90%", height: "100%", margin: '0 auto', display: 'flex', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                <div style={{position: 'relative', width: '100%', height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TextBox text={"Placeholder"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                    <TextBox text={"Select your gamemode"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                </div>
                <div style={{position: 'relative', width: "100%", height: "80px", display: 'flex', margin: '0 auto'}}>
                <TextBox text={"on centerstack"} width={"100%"} textAlign={"center"} fontSize={'xx-large'} textStyle={{color: 'white'}}/>
                </div>   
            </div>

*/