import { useEffect, useContext} from "react";
import Context from "../context/Context";
import { useNetworkingHooks } from "../helpers/NetworkMessaging";

export function ConnectionStatusInterval(props){
    const {onUpdateStatus, intervalRate} = props;

    const context = useContext(Context)

    const Networking = useNetworkingHooks(context.user);

    useEffect(() => {

        setInterval(() => {
            SendConnectionPing();
            
        }, intervalRate ?? 3000);

        return () =>{
            clearInterval(SendConnectionPing);
        }
    }, []);

    function SendConnectionPing(){
        
        let timestamp = Date.now();
        Networking.SendConnectionStatusPing({pingTime: timestamp, playerId: context.user.playerId});
    }

    return null;

}