import React, { useEffect, useContext, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner, PlayerIcon, TextBox, ReconnectionPrompt } from "../Components/PagePrefabs";
import { CarcadeLogo, SplashBackgroundImg } from "../Sprites/ImagesLoader.js"




export default function Lobby(props){
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
   
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages, Latency} = CoreGameConnectionsHooks(context, useGameStatecontext, teamsContext);

    useEffect(() => {
        //OnMount Things happen here
        document.title = "Lobby";

        websocket.AddListener(onPayload);
        websocket.AddDisconnectListener(ShowReconnect);
        window.addEventListener('beforeunload', handleTabClosing);
        //console.log(context.user);
        if(websocket.sessionId === '') Reconnect();
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        //console.log(context.user.playerIcon);
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            websocket.RemoveDisconnectListener(ShowReconnect);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    const ShowReconnect = () => {
        useGameStatecontext.DisconnectedFromServer();
    }

   
    function onPayload(id, payload, senderContext){
        //console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload);
        CheckReconnectMessages(id,payload, senderContext);

        //Game specific calls
        switch(id){
            default:
                break;
        }
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    return (
        <>
        <PageBackground bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"} bkgImage={SplashBackgroundImg} useBkgGradient useLogo Logo={CarcadeLogo} useSmallLogo  />
        <PlayerBanner myColor={context.user.playerColor} Latency={Latency} />
        {useGameStatecontext.GameState.disconnected ? <ReconnectionPrompt /> :
            <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>

                <div style={{position: "relative", width: "100%", height: "100%", margin: '0 auto', display: 'flex', flexFlow: "column nowrap", justifyContent:'flex-start', gap: '12px'}}>
                    <div style={{position: 'relative', width: '100%', height: "20%", display: 'flex', margin: '0 auto'}} />
                    <div style={{position: 'relative', width: "120px", height: "120px", display: 'flex', margin: '0 auto'}}>
                        <PlayerIcon size={120} borderSize={4} myColor={teamsContext.Teams.myteam.color}/>
                    </div>
                    <div style={{position: 'relative', width: "100%", height: "40px", display: 'flex', margin: '0 auto'}}>
                        <TextBox text={context.user.playerName} width={"100%"} textAlign={"center"} fontSize={'xxx-large'} textStyle={{color: '#FFD600'}}/>
                    </div>
                    <div style={{position: 'relative', width: "90%", height: "200px", display: 'flex', margin: '12px auto'}}>
                        <TextBox text={"Please select a game from the center screen to get started. Have Fun! "} width={"100%"} textAlign={"center"} fontSize={'x-large'} textWrap={true} textStyle={{marginTop: '0'}}/>
                    </div>
                </div>
            </div>      
        }
        

        

        </>
        


    );
}
