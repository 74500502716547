import React from "react";
import "./Crown.css"
import { CrownIcon } from "../../Sprites/ImagesLoader";

export default function Crown(props){
const {size} = props;

  const CrownContainer={
    position: 'absolute', 
    left: '6px', 
    top: 'calc(50% - 28px)',
    height: `${size}px` ?? '64px',
    aspectRatio: '1 / 1'
  }

  return(
    <div className="crownIcon" style={{...CrownContainer}}>
      <img src={CrownIcon} height={`${size}px` ?? '64px'} style={{width: '100%', height: '100%'}}/>
    </div>
  )
}