import React, { useEffect, useContext, useState, useRef, useMemo } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext";
import { useNetworkingHooks } from "../helpers/NetworkMessaging";
import { CoreGameConnectionsHooks } from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PageLanding, PlayerBanner } from "../Components/PagePrefabs";
import { GenericReticule, shuffleboardBkgSplash, shuffleboardLogo, shuffleboardTouchArea, sumoLogo } from "../Sprites/ImagesLoader";
import { TeamSelection } from "../Components/TeamSelectionPrefabs";
import { GameplayArea, GameOverResultsPage } from "../Components/GameplayPrefabs";
import { TextBox } from "../Components/PagePrefabs.js";


export default function ShuffleBoard(props){ 
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);
   
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect, RequestGameInformationData} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages, NavigateToPlayPage, Latency} = CoreGameConnectionsHooks(context, gameStateContext, teamsContext);

    const [Invert, setInvert]= useState(false);
    const [SliderValue, setSliderValue] = useState(50);

    useEffect(() => {
        console.log(gameStateContext);
        //OnMount Things happen here
        document.title = "Shuffleboard Controller";
        RequestGameInformationData();
        websocket.AddListener(onPayload);
        websocket.AddDisconnectListener(ShowReconnect);
        window.addEventListener('beforeunload', handleTabClosing);
        
        if(websocket.sessionId === '') Reconnect();

        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            websocket.RemoveDisconnectListener(ShowReconnect);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);


    const handleTabClosing = () => {
        Disconnect();
    }

    const ShowReconnect = () => {
        gameStateContext.DisconnectedFromServer();
    }

    function onPayload(id, payload, senderContext){
        //console.log('sender CONTEXT HERE SHUFFLEBOARD', senderContext);
        CheckCoreGameConnectionMessages(id, payload, senderContext);
        CheckReconnectMessages(id,payload, senderContext, NavigateToPlayPage);

        //Game specific calls
        switch(id){
            default:
                break;
        }
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("Shuffleboard_Aim", payload);
    }

    function TrackingEnded(coords){
        
        var msg = {
            coords: coords,
            slider: SliderValue
        }

        SendSpecificMessageToServer("Shuffleboard_Fire", msg);
    }

    function UpdateSliderValue(value){
        setSliderValue(value);
    }

    const TouchInfo = {
        bkg: shuffleboardTouchArea,
        reticule: GenericReticule,
        sendArray: true,
        rate: 15,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded

    }

    const ShouldBlurBackground = useMemo(() => { return gameStateContext.GameState.isPaused === 'true' ? true : !gameStateContext.GameState.myTurn }, [gameStateContext.GameState.myTurn, gameStateContext.GameState.isPaused])
    
    useEffect(() => {
        //console.log("CONTEXT USER USEEFFECT", context)
    }, [context])

    return (
        <>
            {gameStateContext.GameState.isOnGameLandingPage ? 
                <PageLanding bkgImage={shuffleboardBkgSplash} useLogo Logo={shuffleboardLogo} Latency={Latency}  Disconnected={gameStateContext.GameState.disconnected} />
                :
                <>
                <PageBackground bkgImage={shuffleboardBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!gameStateContext.GameState.joinedGame} Logo={shuffleboardLogo}/>            
                    
                    <PlayerBanner Latency={Latency} />
                    {gameStateContext.GameState.gameOver  ? <GameOverResultsPage Game={'soccer'} GameOverData={gameStateContext.GameOverData} Teams={teamsContext.Teams} isCoreMode={gameStateContext.GameState.isCoreMode}  /> : 
                
                        (!gameStateContext.GameState.teamsSelected ? <TeamSelection TeamContext={teamsContext} GameState={gameStateContext.GameState} smts={(msg) => SendSpecificMessageToServer(msg)} /> : 
                            <GameplayArea 
                            game="Shuffleboard" 
                            TouchBoxInfo={TouchInfo} useBlur={ShouldBlurBackground} 
                            GetSliderValue={(value) => UpdateSliderValue(value) } 
                            GameContext={gameStateContext}
                            TeamsContext={teamsContext}
                            Logo={shuffleboardLogo} />    
                        )      
                    }
                </>
            }
        
        </>
               
    );
 }
