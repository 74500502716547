import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext.js";

import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner, PlayerIcon, TextBox } from "../Components/PagePrefabs";
import { CarcadeLogo, SplashBackgroundImg, LobbyKey, InfoIcon } from "../Sprites/ImagesLoader.js"



export default function JoinScreen(props){
    const context = useContext(Context);
    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);

    const [inputValue, setInputValue] = useState('');
    const [textValue, setTextValue] = useState('Enter Code to Join Lobby');
    const [submitFailed, setSubmitFailed] = useState(false);
    
    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context, gameStateContext, teamsContext);

    const DarkColor = useMemo(() => {return `color-mix(in srgb, gray, black 50%)`}, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setInputValue(code); // Set the code into the input field
        }

        //OnMount Things happen here
        document.title = "Join Carcade";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        //console.log(context.user);
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        //Disconnect();
    }

   
    function onPayload(id, payload, senderContext){
        //console.log(`Payload message recieved ${id}`, payload);
        CheckCoreGameConnectionMessages(id, payload, senderContext);

        //Game specific calls
        switch(id){
            case "phoneClientServerResponse":
                if(payload.sessionId !== null){
                    //console.log(`Session ID i recieved is the following ${payload.sessionId}`);
                   // websocket.sessionId = payload.sessionId;
                    //Then do Join Waiting Room. 
                    JoinWaitingRoom(context.user);


                }else{
                    //Display error Message
                    alert("Error connecting, please check your code and try again");
                }

                break;
            
            case "phoneClientFailedServerResponse":
                setSubmitFailed(true);

            default:
                break;
        }
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    const inputStyle = {
        width: '100%', 
        height: '100%', 
        fontSize: 'xx-large',
        textAlign: 'center',
        textShadow: '0px 2px 4px rgba(255,225,255, 0.375)',
        borderRadius: '8px',
        border: 'none',
        color: 'white',
        letterSpacing: '.25rem',
        backgroundColor: 'rgba(0,0,0,.375)',
        outline: !submitFailed ? 'solid 2px #FFD600' : 'solid 2px #FF0A0A' ,
        outlineOffset: '4px'
    }

    const warningStyle = {
        position: 'relative',
        width: '90%', 
        height: '72px', 
        fontSize: 'xx-large',
        textAlign: 'center',
        textShadow: '0px 2px 4px rgba(255, 150, 150, 0.38)',
        borderRadius: '8px',
        border: 'none',
        background: submitFailed ? `linear-gradient(to bottom, ${'#FF0A0A'} 37.5%, ${'#CA0707'})` : 'rgba(0,0,0, .85)',
    }

    const submitStyle = {
        width: '100%',
        height: '100%',
        background: `linear-gradient(to bottom, ${'#F3DD16'} 37.5%, ${'#F4B000'})`,
        border: 'none',
        boxShadow: "0px 2px 2px 2px rgba(0,0,0, .375)" ,
        borderRadius: '8px',

        fontSize: 'x-large',
        fontWeight: '700',
        fontFamily: 'Rajdhani-Bold',
        textShadow: '2px 2px 4px rgba(0,0,0, 0.1)',
        color: '#462A01'

    }

    

    ///This button will allow for the user to connect to the server. 
    const handleButtonClick = () => {
       // alert(`Text: ${textValue}, Input: ${inputValue}`);
       //console.log("Sending phone client request");
       sessionStorage.setItem("sessionCode", inputValue.toUpperCase());
        SendSpecificMessageToServer(`phoneClientServerJoinRequest`,`${inputValue.toUpperCase()}`);
      };


    return (
        <>
        <PageBackground
            bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"}
            bkgImage={SplashBackgroundImg} // Replace with your actual image
            useBkgGradient={true}
            useLogo={false}
            Logo={CarcadeLogo} // Replace with your actual logo
            useSmallLogo={false}
        />
        <div style={{
            display: 'flex',
            flexFlow: 'column noWrap',
            gap: '24px',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 1, // Ensure this content is on top
            padding: '20px',
            textAlign: 'center',
            color: 'white' // Change text color for visibility against the background
        }}>
            <img src={CarcadeLogo} style={{width: '60%', height: '128px', objectFit: 'scale-down', margin: '0 auto'}}/>
            <TextBox width={'90%'} fontSize={"x-large"} textAlign={"center"} text={"ENTER KEY TO JOIN"} textStyle={{fontWeight: '700', fontFamily: 'Rajdhani',}} bkgStyle={{height: '40px', margin: '0 auto'}} />
            <div style={{position: 'relative', width: '90%', height: '64px', margin: '0 auto'}}>
                
                <input             
                    type='text'
                    placeholder="KEY"
                    maxLength={4}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{...inputStyle, caretColor: '#FFD600'}} // Add some padding for better appearance
                /> 
                <img height={48} src={LobbyKey} style={{position: 'absolute', left: '8px', top: 'calc(50% - 24px)'}} />
            </div>
            <div style={{display: 'flex', margin: '0 auto', ...warningStyle}}>
                <TextBox width={'80%'} fontSize={'clamp(10pt, 10vw, 14pt)'} textAlign={'left'} text={submitFailed ? "INCORRECT KEY" : "INPUT KEY FOUND ON CENTER SCREEN BELOW QR"} textWrap bkgStyle={{position: 'absolute', right: '0'}} textStyle={{fontWeight: '500', fontFamily: 'Rajdhani' }} />
                <img height={24} src={InfoIcon} style={{position: 'absolute', left: '12px', top: 'calc(50% - 12px)'}} />
            </div>      
            <div style={{width: '85%', height: '72px', margin: '12px auto'}}>
                <button onClick={handleButtonClick}
                style={{...submitStyle}}>
                    JOIN LOBBY</button>
            </div>

            
        </div>
    </>
        


    );
}

//        <PageBackground bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"} bkgImage={SplashBackgroundImg} useBkgGradient useLogo Logo={CarcadeLogo} useSmallLogo  />

