
import React, { useEffect, useContext, useState, useRef, useMemo } from "react"; 
import { useNavigate}  from "react-router-dom";
import { UseNavitageToPage } from "../Components/PageNavigationElement";
import TeamsContext from "../context/TeamsContext";
import GameStateContext from "../context/GameStateContext";
import websocket from "../helpers/WebSocket";
import { useTeamSelectionData } from "./TeamSelectionHooks";
import Context from "../context/Context";


export function CoreGameConnectionsHooks(usercontext, gamestateContext, teamsSelectionContext){
    const navigate = useNavigate();
    const {tryNavigatePage} = UseNavitageToPage();
    const {JoinedTeam, SetTeamsSelected, setIsOnGameLandingPage, SetTeamSelectionPage, CanStartGame, IsGameOver, ResetGameState, SetIsPaused, IsMyTurn, SetCurrentPlayerAvatar} = gamestateContext;

    const teamsContext = useContext(TeamsContext);
    const gameStateContext = useContext(GameStateContext);

    const [latency, setLatency] = useState(null);

    const CheckCoreGameConnectionMessages = (id, payload, senderContext) =>{
        let isMe = IsMe(senderContext);

        //console.log('gamestateContext', gamestateContext)
        //console.log('teamsSelectionContext', teamsSelectionContext)
        
        //console.log(`We are checking for coremessage: `, id, payload, senderContext);

        switch(id){
            case "PhoneConnectionStatusPong":  
            UpdatePhoneConnectionStatus(payload);
                break;

            case "GoToWaitingRoom":
                navigate("/");
                ResetGameContextValues();
                teamsContext.SelectTeamToPlay("Neutral");
                break;
            case "JoinedGame":
                if(isMe){
                    JoinedTeam();
                }
                break;   

            case "ResponseToJoinRoomRequest":
                if(isMe){
                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    //console.log(senderContext);
                    teamsContext.SelectTeamToPlay("Neutral");
                }
                break;
            case "ResponseToJoinRoomRequest_Teamless":
                if(isMe){
                    JoinedTeam();                    
                    SetCurrentPlayerAvatar(senderContext);
                }
                break;

            case "DisconnectedPlayer":
                if(payload.playerId === usercontext.user.playerId){
                    gamestateContext.DisconnectedFromServer();
                }
                break;        
            case "ReconnectToRoomResponse":
                if(isMe){
                    //console.log("Got the reconnect response");
                    
                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    
                    teamsContext.SelectTeamToPlay(payload.teamSelected);
                    
                    SetTeamsSelected(payload.teamSelectionCompletedStatus === 'True');

                    IsMyTurn(payload.currPlayersTurn === usercontext.user.playerId);               
                }
                break;
            case "ReconnectToRoomResponse_Teamless":
                if(isMe){
                    //console.log("Got the reconnect response_Teamless");

                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    
                    teamsContext.SelectTeamToPlay("Teamless");
                    SetTeamsSelected(true);

                    IsMyTurn(payload.currPlayersTurn === usercontext.user.playerId);               
                }
                break;
                case "GameDataInformation":
                    //console.log("In game data information method");
                    
                    SetTeamsSelected(payload.teamSelectionCompletedStatus === 'True');
                    //console.log("Here is the game data variables, lobby", payload.isOnLobbyPage, "team select ", payload.isOnTeamSelectionPage);
                    if(payload.isOnTeamSelectionPage === 'True'){
                        //console.log("WE GOT GAME DATA AND WE ARE ON THE TEAM SELECT PAGE");
                        setIsOnGameLandingPage(false);
                        SetTeamSelectionPage(true, payload.isCoreMode);
                    }else if(payload.isOnLobbyPage === 'True'){
                        //console.log("WE GOT GAME DATA AND WE ARE ON THE GAME LOBBY");
                        setIsOnGameLandingPage(true);
                        SetTeamSelectionPage(false, false);
                    }
                    
                    break;
            case "GameComplete":
                UpdateGameOverData(payload);
                break;

            case "InitiateRematch":
                IsGameOver(false);
                break;

            case "TeamSelectionCompleted":
                SetTeamsSelected(true);
                break;

            case "OnTeamSelectScreen":
                //show team selection and start game button
                setIsOnGameLandingPage(false);
                SetTeamSelectionPage(true, payload.isCoreMode);
                break;

            case "GameLandingPage":
                //Hide team selection and start game button and show SPECIFIC GAME LOBBY
                //console.log("WE ARE ON THE GAME LANDING PAGE");
                setIsOnGameLandingPage(true);
                SetTeamSelectionPage(false, false);
                teamsContext.SelectTeamToPlay("Neutral");
                ResetGameContextValues();
                break;
            
            case "SetCurrentTurn":
                //console.log("Incoming PlayerID: " , payload.currPlayersTurn, usercontext.user.playerId );
                IsMyTurn(payload.currPlayersTurn === usercontext.user.playerId);
            break;

            case "RequestCurrentSelectedTeam":
                if(isMe){
                    //console.log(`Checking my currently selected team: ${teamsSelectionContext.Teams.myTeam}`);
                    teamsContext.RequestCurrentlySelectedTeam();
                }
                break;
            case "SetStartGameButtonState":
                //console.log(`SetStartGameButtonState listener called. here is the check for player: ${payload.playerId} : ${context.user.playerId}`);
                CanStartGame(payload.readyState.toLowerCase() === 'true');

               
                break;

            case "ResetPhoneController":
                    ResetGameState();
                    ResetGameContextValues();
                    teamsContext.SelectTeamToPlay("Neutral");
                    break;
            
            case "NavigateToPage":
                tryNavigatePage(payload, usercontext.user.playerId, websocket.sessionId);                
                break;

            case "TeamStateUpdate":
                //console.log("Recieved a team state update", payload);
                //receive message letting players know what teams are disabled and what are not 
                //One message that says who has control of each team, and if the teams are now disabled or still active 

                //console.log("CurrentState of teambuttondisable: ", teamsSelectionContext.Teams.isawayTeamButtonDisabled, teamsSelectionContext.Teams.ishomeTeamButtonDisabled);
                teamsContext.setHomeButtonDisableState(payload.homeTeamButtonDisabled);
                teamsContext.setAwayButtonDisableState(payload.awayTeamButtonDisabled);

                let num = {home: payload.playersReadyHome, away:payload.playersReadyAway};
                teamsContext.PlayersReadyToPlay(num);

                break;
            case "TeamSetup":
                //console.log(`setting up the team colors and data now, home team name: ${payload.homeTeam.name}`);
                teamsContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
                teamsContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});

                break;
            case "SetPauseGameStatus":
                gameStateContext.SetIsPaused(payload.gamePaused);
                break;


            default:
                break;


        }

        
    }

    const IsMe = (sender) => {
        //if(sender === null || sender === undefined) console.log('Sender is null in IsMeCheck');
        //else console.log(`Sender context isnt null, player id is ${sender.playerId} and user context is: ${usercontext.user.playerId}`)

        if(sender === null || sender === undefined) return false;
        else return sender.playerId === usercontext.user.playerId;
    }

    const ResetGameContextValues = () => {
        gameStateContext.SetIsPaused(false);

        teamsContext.setHomeButtonDisableState(false);
        teamsContext.setAwayButtonDisableState(false);

        let num = {home: 0, away: 0};
        teamsContext.PlayersReadyToPlay(num);

        usercontext.set("selectedPlayerPuck", 0);
        usercontext.set("spinValue", 50);
    }

    const UpdatePhoneConnectionStatus = (payload) => {
        if(payload.playerId ===  usercontext.user.playerId){
            
            let delta = (Date.now() - payload.pingTime);
            setLatency(delta);

            clearTimeout(usercontext.user.latencyTimerId);

            let to = setTimeout(() => {
                LatentcyTimeout();
                
            }, 15000);

            usercontext.set("latencyTimerId", to);
        }
        
    }

    const LatentcyTimeout = () => {
        usercontext.set("latencyTimerId", null);    
        setLatency(null);
    }

    const UpdateGameOverData = (data) =>{              
        gameStateContext.UpdateGameOverData(data);
        IsGameOver(true);
        
    }

    const NavigateToPlayPage = () =>{
        let sessionCode = sessionStorage.getItem("sessionCode");
            navigate(`/play?code=${sessionCode}`);
    }

    return{
        CheckCoreGameConnectionMessages, 
        IsMe, 
        NavigateToPlayPage, 
        Latency: useMemo(() => {return latency}, [latency])} ;
}