import React, { useContext, useMemo, useState, useEffect } from "react";
import Context from "../context/Context.js";
import "../Styles/FigmaStyle.css";
import TouchFingerTracker, { TouchPlayerSelection, TouchSlider } from "./TouchFingerTracker.js"
import { PageBackground, TextBox } from "./PagePrefabs.js";
import { TeamSelectionBanner, GameSelectionBanner, GameOverResultsBanner, GameOverResultsTeamEntry } from "./TeamSelectionPrefabs.js";
import { GetGameIconImage, GetGameSplashImage } from "../Sprites/ImagesLoader.js";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner.js";


export function GameplayArea(props){
    const {game, TouchBoxInfo, useBlur, GetSliderValue, Logo, GameContext, TeamsContext} = props;
    const [myTurnVibrated, setMyTurnVibrate] = useState(false);
    const context = useContext(Context);

    const Spectating = useMemo(() => {return TeamsContext.Teams.myteam === TeamsContext.Teams.neutralteam}, [TeamsContext]);
    const Paused = useMemo(() => {return GameContext.GameState.isPaused === 'true'}, [GameContext]);
    const MyTurn = useMemo(() => {return GameContext.GameState.myTurn}, [GameContext]);

    const Team = useMemo(() => {return TeamsContext.Teams.myteam}, [TeamsContext]);
    const OpposingTeam = useMemo(() => {return TeamsContext.Teams.otherteam}, [TeamsContext]);

    useEffect(()=> {
        if(MyTurn){
            if(!myTurnVibrated){
                setMyTurnVibrate(true);
                VibrateDevice();             
            }
        }
        else{
            if(myTurnVibrated) setMyTurnVibrate(false);
        }


    }, [MyTurn]);

    function VibrateDevice(){
        if("vibrate" in navigator) {
            navigator.vibrate(100);
        }

    }

    const bkgBlurOverlay = {
        position: useBlur ? "relative": '',
        top: useBlur ? "0": '',
        left: useBlur ? "0": '',
        height: useBlur ? "100%": '',
        width: useBlur ? "100%": '',
        backgroundColor: useBlur ? 'rgba(0,0,0, .5)': '',
        filter: useBlur ? 'blur(.2rem) grayscale(50%) saturate(5%) brightness(10%)'   : ''   
    }

    return(
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>        
            {Team != null  ? <TeamSelectionBanner Team={Team}/> : <></> }
            <div style={{...bkgBlurOverlay}} >
                <div style={{height: '24px'}}/>
                {  MyTurn ? 
                <>
                    <TouchFingerTracker game = {game} bkgImg={TouchBoxInfo.bkg} reticuleImg={TouchBoxInfo.reticule} sendArray={TouchBoxInfo.sendArray} intervalRate={TouchBoxInfo.rate}
                        onUpdateTracking={TouchBoxInfo.onUpdateTracking}
                        onTrackingEnd={TouchBoxInfo.onTrackingEnd} 
                    />
                    <div style={{height: '24px'}}/>
                    {game.toLowerCase() == 'shuffleboard' ? <TouchSlider GetSliderValue={(value) => GetSliderValue(value)} /> : <></>}
                    {game.toLowerCase() == 'soccer' ? <TouchPlayerSelection Selection={context.user.selectedPlayerPuck} Team={Team} Tracking={TouchBoxInfo.tracking} btnCount={GameContext.GameState.isCoreMode ? 5 : 1} /> : <></>}
                    {game.toLowerCase() == 'football' ? <TouchSlider GetSliderValue={(value) => GetSliderValue(value)} /> : <></>}
                    {game.toLowerCase() == 'chuckit' ? <TouchPlayerSelection Selection={context.user.selectedPlayerPuck} Team={Team} btnCount={3}/> : <></>}

                </> : <></> }        
            </div>
            <GameOverlays Logo={Logo} OverlayState={{useBlur: useBlur, Spectating: Spectating, Paused: Paused}} OpposingTeam={OpposingTeam}/>   
        </div>
    );
}

export function GameplayAreaTeamless(props){
    const {game, TouchBoxInfo, useBlur, Spectating, Logo} = props;
    
    const bkgBlurOverlay = {
        position: useBlur ? "relative": '',
        top: useBlur ? "0": '',
        left: useBlur ? "0": '',
        height: useBlur ? "100%": '',
        width: useBlur ? "100%": '',
        backgroundColor: useBlur ? 'rgba(0,0,0,.25)': '',
        filter: useBlur ? 'blur(.2rem) saturate(50%) brightness(25%)'   : ''   
    }

    return(
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>      
            <GameSelectionBanner Game={{name: game}} />
            <div style={{...bkgBlurOverlay}} >
                <div style={{height: '24px'}}/>
                {!Spectating ? 
                <>
                <TouchFingerTracker game = {game} bkgImg={TouchBoxInfo.bkg} reticuleImg={TouchBoxInfo.reticule} sendArray={TouchBoxInfo.sendArray} intervalRate={TouchBoxInfo.rate}
                    onUpdateTracking={TouchBoxInfo.onUpdateTracking}
                    onTrackingEnd={TouchBoxInfo.onTrackingEnd} 
                />
                <div style={{height: '24px'}}/>
                </>
                : <></> }        
            </div>    
            {Spectating ? <GameplayOverlay_Spectating useBlur Logo={Logo} /> : <GameplayOverlay_AnothersTurn useBlur={useBlur} OpposingTeam={{name: '---', color: 'orange'}}/>}
            
            

        </div>
    );
}

export function GameOverResultsPage(props){
    const {game, GameOverData, Teams, isCoreMode} = props;

    const endData = useMemo(() => {return {
        winningteam: GameOverData.HomeWon === 'true' ? Teams.hometeam : Teams.awayteam,
        winningscore: GameOverData.winningScore,
        losingteam: GameOverData.HomeWon === 'true' ? Teams.awayteam : Teams.hometeam,
        losingscore: GameOverData.losingScore,
        tieGame: GameOverData.winningScore == GameOverData.losingScore
    }}, [GameOverData, Teams]);

    const bkgBlurOverlay = {
        position: "relative",
        top: '0',
        left: '0',
        height: "100%",
        width: "100%", 
    }

    const overlayTextArea = {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        height: '48px',
    }

    return(
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>
            <div style={{height: '24px'}}/> 
            <div style={{...bkgBlurOverlay}} >   
                <div style={{...overlayTextArea}}>
                    <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} textWrap={true} text={"RESULTS"} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600'}} />
                </div>       
                <div style={{height: '24px'}}/> 
                {endData.winningteam != null ? <GameOverResultsTeamEntry Team={endData.winningteam} score={endData.winningscore} won={!endData.tieGame} isCoreMode={isCoreMode} height={96} /> : <></>} 
                <div style={{height: '12px'}}/> 
                {endData.losingteam != null && isCoreMode ? <GameOverResultsTeamEntry Team={endData.losingteam} score={endData.losingscore} won={false} height={endData.tieGame ? 96 : 72}/>  : <></> }
                
            </div>  
        </div>
    );
}

function GameOverlays(props){
    const {OverlayState, Logo, OpposingTeam} = props;

    return(
        <>
            {OverlayState.Spectating ? <GameplayOverlay_Spectating useBlur Logo={Logo} /> :
                (OverlayState.Paused ? <GameplayOverlay_Pause useBlur Logo={Logo} /> : 
                    <GameplayOverlay_AnothersTurn useBlur={OverlayState.useBlur} OpposingTeam={OpposingTeam}/> )                
            }
        </>
    );
}

function GameplayOverlay_AnothersTurn(props){
    const {useBlur, OpposingTeam} = props;

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '10'
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '20% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        width: '100%',
        height: '48px',
    }

    const overlayLoaderBox = {
        position: 'relative', 
        display: 'flex',
        margin: '0 auto',
        width: '120px', 
        height: '120px',      
    }

    const OtherTeamName = useMemo(() => {
        let s = "'s"
        if(OpposingTeam.name.slice(-1) === "s") s = "'";

        return OpposingTeam.name + s;
    }, [OpposingTeam])



    return(
        <>
        {useBlur ? 
            <div style={{...overlayBackgroundArea}}>
                <div style={{...overlayBox}}>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xx-large"} textAlign={"center"} textWrap={true} text={"PLEASE WAIT"} />
                    </div>
                    <div style={{...overlayLoaderBox}}>
                        <LoadingSpinner size={60} thickness={6} />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} text={OtherTeamName} textStyle={{fontFamily: 'Rajdhani-Bold', color: `${OpposingTeam.color}`}} />
                        
                    </div>
                    <div style={{...overlayTextArea, height: '30px', marginTop:'-12px'}}>
                        <TextBox width={'100%'} fontSize={"xx-large"} textAlign={"center"} text={"TURN"}  />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"medium"} textAlign={"center"} text={"Another player is currently taking their turn."} />
                    </div>
                    
                </div>
            </div> : <></>
        }
        </>
    );
}

function GameplayOverlay_Spectating(props){
    const {useBlur, Logo} = props;

    const gameLogo = useMemo(() => { return  Logo}, [Logo])

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '10',
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '37.5% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        width: '100%',
        height: '48px',
    }

    const CenteredLogo = {
        position: 'relative',
        display: 'flex',
        height: 'auto',
        width: '100%',
        objectFit: 'fit',
        filter: "drop-shadow(12px 12px 8px black)",
        margin: '0 auto',
        marginBottom: '92px'
    }


    return(
        <>
        {useBlur ? 
            <div style={{...overlayBackgroundArea}}>
                <div style={{...overlayBox}}>
                    <img id="GameLogo" src={gameLogo} style={{...CenteredLogo}} />
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} textWrap={true} text={"SPECTATING"} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600'}} />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"medium"} textAlign={"center"} text={"Game currently in progress"} />
                    </div>
                    
                </div>
            </div> : <></>
        }
        </>
    );
}

function GameplayOverlay_Pause(props){
    const {useBlur, Logo} = props;

    const gameLogo = useMemo(() => { return  Logo}, [Logo])

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '10',
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '37.5% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '-32px auto',
        width: '100%',
        height: '48px',
    }

    const CenteredLogo = {
        position: 'relative',
        display: 'flex',
        height: 'auto',
        width: '100%',
        objectFit: 'fit',
        filter: "drop-shadow(12px 12px 8px black)",
        margin: '0 auto',
        marginBottom: '92px'
    }


    return(
        <>
        {useBlur ? 
            <div style={{...overlayBackgroundArea}}>
                <div style={{...overlayBox}}>
                    <img id="GameLogo" src={gameLogo} style={{...CenteredLogo}} />
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} textWrap={true} text={"GAME PAUSED"} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600'}} />
                    </div>                    
                </div>
            </div> : <></>
        }
        </>
    );
}

export function RotationWarning(props){
    const {glow, orientation, c} = props;

    const location = useMemo(() => window.location.pathname);

    const myLogo = useMemo(() => {return GetGameIconImage(location)}, [location]);
    const myBkg = useMemo(() => {return GetGameSplashImage(location)}, [location]);

    const WarningBox = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        boxShadow: 'inset 0 0 256px 12px rgba(0,0,0, .5)'
    }

    const SpanStyle = {
        display: 'flex',

        width: '75%',
        height: '75%',
        margin: '0 auto',
        lineHeight: '72px',

        pointerEvents: 'none', 
        userSelect: 'none',

        textAlign: 'center',
        fontSize: 'clamp(40pt, 10vw, 56pt)',
        fontWeight: '700',
        fontFamily: 'Rajdhani-Bold',
        textShadow: '2px 2px 4px rgba(0,0,0, 0.375)',
        color: '#FFD600'
    }

    return(
        <>
         <PageBackground
                     bkgColor={"rgba(36.975, 12.75, 175.9175, 1)"}
                     bkgImage={myBkg} // Replace with your actual image
                     useBkgGradient={true}
                     useLogo={false}
                     Logo={myLogo} // Replace with your actual logo
                     useSmallLogo={false}
                     isLandscape={true}
        />
        <div style={{...WarningBox}}>
                <img src={myLogo} style={{width: '100%', height: '25%', objectFit: 'scale-down', margin: '12px auto'}}/>
                <span style={{...SpanStyle}}>Please Rotate Your Device</span>

        </div>
        
        </>
        


    );

}
